import { Outlet } from "react-router";
import { ToastContainer } from "react-toastify";
import Copyclaim from "./components/copyclaim";

function App() {
  return (
    <>
      <div className="bg-gray-50 text-slate-800 h-fit min-h-screen antialiased flex justify-center">
        <div className="w-10/12 sm:w-1/2 mt-12">
          <Outlet />
          <Copyclaim />
        </div>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        limit={3}
        draggable
        className="text-slate-700 text-sm"
      />
    </>
  );
}

export default App;
