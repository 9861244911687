import axios from "axios";
import authHeader from "./auth_header";

const API_URL = "https://api.synfonex.de/fliegenlernen";

class CategoryService {
  getAllCategories() {
    return axios.get(`${API_URL}/category`, { headers: authHeader() });
  }
}

export default new CategoryService();
