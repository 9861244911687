import React, { useEffect, useState } from "react";
import Category from "../../../models/Category";
import TrainingType from "../../../models/TrainingType";
import categoryService from "../../../services/category.service";

type CategorySelectionProps = {
  callback: (pCount: number, pType: TrainingType, pCategory: string) => void;
};

function CategorySelection(props: CategorySelectionProps) {
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState<Category[]>([]);

  const [questionCount, setQuestionCount] = useState(50);
  const [type, setType] = useState(TrainingType.all);
  const [category, setCategory] = useState("");

  const handleQuestionCountChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let { value, min, max } = event.target;
    setQuestionCount(
      Math.max(Number(min), Math.min(Number(max), Number(value)))
    );
  };

  async function fetchCategories() {
    let response = await categoryService.getAllCategories();
    response.data.forEach((pCategory: Category) => {
      setCategories((prevState) => [pCategory, ...prevState]);
    });
  }

  useEffect(() => {
    fetchCategories();
  }, []);

  const blueButton =
    "bg-blue-500 col-span-1 text-sm block text-white rounded py-2 my-1 px-4 hover:bg-white border-2 text-center border-transparent hover:border-blue-500 hover:text-blue-500 transition-all";
  const activeBlueButton =
    "bg-white col-span-1 text-sm block text-blue-500 rounded py-2 my-1 px-4 border-2 text-center border-transparent border-blue-500 text-blue-500 transition-all";

  const activeSkyButton =
    "bg-white col-span-1 text-sm block text-white rounded py-2 my-1 px-4 border-2 text-center border-transparent border-sky-500 text-sky-500 transition-all";

  const skyButton =
    "bg-sky-500 col-span-1 text-sm block text-white rounded py-2 my-1 px-4 hover:bg-white border-2 text-center border-transparent hover:border-sky-500 hover:text-sky-500 transition-all";

  return (
    <div>
      <h3 className="font-bold mt-4">Was möchten Sie üben?</h3>
      <hr />
      <div className="grid grid-cols-4 gap-4 mt-2">
        <button
          onClick={() => setType(TrainingType.all)}
          className={type === TrainingType.all ? activeBlueButton : blueButton}
        >
          Gesamter Fragenkatalog
        </button>
        <button
          onClick={() => setType(TrainingType.wrong)}
          className={
            type === TrainingType.wrong ? activeBlueButton : blueButton
          }
        >
          Falsch Beantwortet
        </button>
        <button
          onClick={() => setType(TrainingType.unanswered)}
          className={
            type === TrainingType.unanswered ? activeBlueButton : blueButton
          }
        >
          Unbeantwortet
        </button>
        <button
          onClick={() => setType(TrainingType.wrong_unanswered)}
          className={
            type === TrainingType.wrong_unanswered
              ? activeBlueButton
              : blueButton
          }
        >
          Falsch & Unbeantwortet
        </button>
      </div>

      <h3 className="font-bold mt-4">... in welcher Kategorie?</h3>
      <hr />
      <div className="mt-2 grid grid-cols-4 gap-4">
        <button
          onClick={() => setCategory("")}
          className={category === "" ? activeBlueButton : blueButton}
        >
          Alle
        </button>
        {categories.map((cat) => (
          <button
            key={cat.id?.toString()}
            id={cat.id?.toString()}
            onClick={() => setCategory(cat.short.toString())}
            className={category === cat.short ? activeSkyButton : skyButton}
          >
            {cat.name_de}
          </button>
        ))}
      </div>
      <h3 className="font-bold mt-4">... und wie viele?</h3>
      <hr />
      <input
        className="bg-slate-200 rounded px-2 py-1 w-64 mt-4"
        type={"number"}
        defaultValue={questionCount}
        min={1}
        max={500}
        onChange={handleQuestionCountChange}
      ></input>
      <button
        className={blueButton + " mt-12"}
        onClick={() => props.callback(questionCount, type, category)}
      >
        Weiter
      </button>
    </div>
  );
}

export default CategorySelection;
