import axios from "axios";
import jwt from "jwt-simple";
import { toast } from "react-toastify";
import User from "../models/User";

const API_URL = "https://api.synfonex.de/fliegenlernen";

class AuthService {
  async login(pUsername: String, pPassword: String) {
    let response = await axios.post(`${API_URL}/user/login`, {
      username: pUsername,
      password: pPassword,
    });

    if (response.status === 403) {
      toast.error("Falscher Nutzername und/oder Passwort");
      return false;
    }

    console.log(response.data);
    localStorage.setItem("user", JSON.stringify(response.data.user));
    return true;
  }

  logout = () => {
    localStorage.removeItem("user");
    window.location.href = "/";
  };

  getCurrentUser() {
    return JSON.parse(localStorage.getItem("user") ?? "{}") as User;
  }
}

export default new AuthService();
