import axios from "axios";
import authService from "./auth.service";
import authHeader from "./auth_header";

const API_URL = "https://api.synfonex.de/fliegenlernen";

class StatsService {
  getQuestionCountAll() {
    return axios.get(`${API_URL}/stats/questionCountAll`, {
      headers: authHeader(),
    });
  }

  getQuestionCountAnsweredCorrect() {
    return axios.get(
      `${API_URL}/stats/questionCountAnsweredCorrect?userId=${
        authService.getCurrentUser().id
      }`,
      {
        headers: authHeader(),
      }
    );
  }
}

export default new StatsService();
