import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { number } from "yup/lib/locale";
import Copyclaim from "../../components/copyclaim";
import authService from "../../services/auth.service";
import statsService from "../../services/stats.service";

function DashboardPage() {
  const currentUser = authService.getCurrentUser();

  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [correctAnsweredCount, setCorrectAnsweredCount] = useState(0);

  let greenPercentage =
    Number((correctAnsweredCount * 100) / totalCount).toFixed(0) + "%";

  async function fetchData() {
    setLoading(true);

    let total = await statsService.getQuestionCountAll();
    let correct = await statsService.getQuestionCountAnsweredCorrect();

    if (!(total && correct)) return;
    setTotalCount(total.data[0]["COUNT(q.id)"]);
    setCorrectAnsweredCount(correct.data[0]["COUNT(q.id)"]);

    setLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (correctAnsweredCount) {
      setLoading(false);
    }
  }, [correctAnsweredCount]);

  return (
    <>
      <h1 className="text-3xl md:text-4xl">
        Willkommen zurück, {currentUser.firstname} 👋
      </h1>
      <div className="bg-white shadow rounded-md mt-12 py-4 px-8 hover:shadow-md transition-all">
        <h2 className="text-2xl mb-6">PPL(A)</h2>
        <p className="text-sm font-semibold text-slate-500">
          Dein Lernfortschritt:
        </p>
        {loading ? (
          <p>Lade...</p>
        ) : (
          <div>
            <div className="relative mt-1">
              <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-zinc-200">
                <div
                  style={{
                    width: greenPercentage,
                  }}
                  className={"shadow-none text-white bg-green-500"}
                ></div>
              </div>
            </div>
            <div>
              {correctAnsweredCount} / {totalCount}
            </div>
          </div>
        )}
        <div className="mt-6 gap-x-4 grid md:flex md:justify-end">
          <Link
            to="/training"
            className="bg-blue-500 text-small md:text-base block text-white rounded py-2 my-1 px-8 hover:bg-white border-2 text-center border-transparent hover:border-blue-500 hover:text-blue-500 transition-all"
          >
            🏋️ Training
          </Link>
          <button className="hover:shadow transition-all py-2 my-1 px-8 bg-white rounded">
            Prüfung
          </button>
        </div>
      </div>
      <div className="bg-white shadow rounded-md mt-12 hover:shadow-md transition-all py-4 px-8 ">
        <h2 className="text-2xl ">Sprechfunk</h2>
        <h3 className=" text-slate-500">
          Ausgewählte Lizenz: <span className="font-semibold">BZF II</span>
        </h3>
      </div>
    </>
  );
}

export default DashboardPage;
