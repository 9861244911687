import { ErrorMessage, Field, Form, Formik } from "formik";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import Copyclaim from "../components/copyclaim";
import authService from "../services/auth.service";

function LoginPage() {
  const navigate = useNavigate();
  const SignInSchema = yup.object().shape({
    username: yup.string().required("Dieses Feld darf nicht leer sein"),
    password: yup.string().required("Dieses Feld darf nicht leer sein"),
  });

  return (
    <div className="min-h-screen bg-cover flex flex-col align-middle items-center justify-center">
      <div className="bg-white shadow-lg rounded-md py-6 px-8 w-96">
        <h1 className="text-3xl text-center">Anmelden</h1>

        <Formik
          initialValues={{
            username: "",
            password: "",
          }}
          onSubmit={async (values) => {
            let success = await authService.login(
              values.username,
              values.password
            );
            if (success) navigate("/dashboard");
            else toast.error("Fehler");
          }}
          validationSchema={SignInSchema}
          validateOnBlur
          validateOnChange
        >
          <Form className="space-y-6 w-full mt-6">
            <div className="flex flex-col w-full">
              <label className="text-xs font-bold text-slate-500">
                Benutzername
              </label>
              <Field
                required
                autoComplete="username"
                className="w-full px-3 py-2 border border-slate-300 rounded-md mt-2 focus:border-slate-400 transition-all"
                placeholder="maxmustermann"
                name="username"
              />
              <ErrorMessage
                component="p"
                className="text-xs mt-2 font-semibold text-red-400"
                name="username"
              />
            </div>

            <div className="flex flex-col w-full">
              <label className="text-xs font-bold text-slate-500">
                Passwort
              </label>
              <Field
                required
                autoComplete="password"
                type="password"
                className="w-full px-3 py-2 border border-slate-300 rounded-md mt-2 focus:border-slate-400 transition-all"
                placeholder="·············"
                name="password"
              />
              <ErrorMessage
                component="p"
                className="text-xs mt-2 font-semibold text-red-400"
                name="password"
              />
            </div>

            <div className="flex justify-between">
              <div className="flex items-center">
                <input type="checkbox" className="rounded-full" />
                <label className="ml-2 block text-sm">
                  Angemeldet bleiben?
                </label>
              </div>
              <Link to="/forgotPassword" className="text-sm text-sky-600">
                Passwort vergessen?
              </Link>
            </div>

            <button
              type="submit"
              className="w-full bg-sky-700 text-white rounded-md py-2 px-3 hover:shadow-lg  hover:bg-sky-800 transition-all"
            >
              Absenden
            </button>
          </Form>
        </Formik>
      </div>
      <Copyclaim />
    </div>
  );
}

export default LoginPage;
