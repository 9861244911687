import axios from "axios";
import authService from "./auth.service";
import authHeader from "./auth_header";

const API_URL = "https://api.synfonex.de/fliegenlernen";

class AnswerService {
  postAnswer(pQuestionId: string, pCorrect: boolean, pSelected_answer: string) {
    if (authService.getCurrentUser().id === undefined) return;

    return axios.post(
      `${API_URL}/answer`,
      {
        userId: authService.getCurrentUser().id,
        questionId: pQuestionId,
        correct: pCorrect,
        selected_answer: pSelected_answer,
      },
      { headers: authHeader() }
    );
  }
}

export default new AnswerService();
