import axios from "axios";
import TrainingType from "../models/TrainingType";
import authService from "./auth.service";
import authHeader from "./auth_header";

const API_URL = "https://api.synfonex.de/fliegenlernen";

class QuestionService {
  getQuestion(pCount: number, pCategory: string, pType: TrainingType) {
    let userId = authService.getCurrentUser().id;

    return axios.get(
      `${API_URL}/question/?count=${pCount}&category=${pCategory}&type=${TrainingType[pType]}&userId=${userId}`,
      { headers: authHeader() }
    );
  }
}

export default new QuestionService();
