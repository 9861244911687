import { useEffect, useState } from "react";
import Question from "../../../models/Question";
import TrainingType from "../../../models/TrainingType";
import questionService from "../../../services/question.service";

type QuestionViewProps = {
  question: Question;
  currentQuestionNumber: number;
  totalQuestionNumber: number;
  skip: () => void;
  submitQuestion: (pSelectedAnswer: string, pCorrect: boolean) => void;
};

function QuestionView(props: QuestionViewProps) {
  const [showAnswer, setShowAnswer] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState("");

  const changeSelection = (
    pEvent?: React.ChangeEvent<HTMLInputElement>,
    pValue?: string
  ) => {
    if (showAnswer) return;

    if (pEvent) {
      let { value } = pEvent.target;
      setSelectedAnswer(value);
    } else if (pValue) {
      setSelectedAnswer(pValue);
    }
  };

  const submit = () => {
    if (showAnswer === false) {
      return setShowAnswer(true);
    }
    setSelectedAnswer("");
    setShowAnswer(false);
    props.submitQuestion(
      selectedAnswer,
      props.question.correct_answer === selectedAnswer
    );
  };

  const nextQuestion = () => {
    setSelectedAnswer("");
    setShowAnswer(false);
    props.skip();
  };

  return (
    <div className="flex flex-col justify-center mt-12">
      <div className="grid grid-cols-3 min-w-full">
        <p className="text-sm text-slate-500">
          ID: {props.question.id} | Syllabus: {props.question.syllabus}
        </p>
        <p className="text-center">
          {props.currentQuestionNumber + 1}/{props.totalQuestionNumber}
        </p>
        <button
          onClick={() => nextQuestion()}
          className="text-sm text-slate-500 text-right"
        >
          Frage überspringen {">"}
        </button>
      </div>

      <h3 className="text-xl my-6 font-bold text-center">
        {props.question.question_stem}
      </h3>

      {props.question.annex && (
        <div className="flex items-center justify-center">
          <img
            src={"https://tillianbo.com/assets/" + props.question.annex}
            alt={props.question.annex}
            width={256}
          />
        </div>
      )}

      <div className="mt-4">
        <div
          className="my-2 cursor-pointer flex items-center"
          onClick={() => changeSelection(undefined, "A")}
        >
          <input
            type="radio"
            className="my-2 mr-2"
            checked={selectedAnswer === "A"}
            onChange={changeSelection}
            value={"A"}
          />{" "}
          <AnswerText
            text={props.question.a}
            showCorrect={showAnswer && props.question.correct_answer === "A"}
            showIncorrect={showAnswer && selectedAnswer !== "A"}
            showCorrectSelected={
              showAnswer &&
              props.question.correct_answer === "A" &&
              selectedAnswer === "A"
            }
            showIncorrectSelected={
              showAnswer &&
              props.question.correct_answer !== "A" &&
              selectedAnswer === "A"
            }
          />
        </div>
        <hr />
        <div
          className="my-2 cursor-pointer flex items-center"
          onClick={() => changeSelection(undefined, "B")}
        >
          <input
            type="radio"
            className="my-2 mr-2"
            checked={selectedAnswer === "B"}
            onChange={changeSelection}
            value={"B"}
          />{" "}
          <AnswerText
            text={props.question.b}
            showCorrect={showAnswer && props.question.correct_answer === "B"}
            showIncorrect={showAnswer && selectedAnswer !== "B"}
            showCorrectSelected={
              showAnswer &&
              props.question.correct_answer === "B" &&
              selectedAnswer === "B"
            }
            showIncorrectSelected={
              showAnswer &&
              props.question.correct_answer !== "B" &&
              selectedAnswer === "B"
            }
          />
        </div>
        <hr />
        <div
          className="my-2 cursor-pointer flex items-center"
          onClick={() => changeSelection(undefined, "C")}
        >
          <input
            type="radio"
            className="my-2 mr-2"
            checked={selectedAnswer === "C"}
            onChange={changeSelection}
            value={"C"}
          />{" "}
          <AnswerText
            text={props.question.c}
            showCorrect={showAnswer && props.question.correct_answer === "C"}
            showIncorrect={showAnswer && selectedAnswer !== "C"}
            showCorrectSelected={
              showAnswer &&
              props.question.correct_answer === "C" &&
              selectedAnswer === "C"
            }
            showIncorrectSelected={
              showAnswer &&
              props.question.correct_answer !== "C" &&
              selectedAnswer === "C"
            }
          />
        </div>
        <hr />
        <div
          className="my-2 cursor-pointer flex items-center"
          onClick={() => changeSelection(undefined, "D")}
        >
          <input
            type="radio"
            className="my-2 mr-2"
            checked={selectedAnswer === "D"}
            onChange={changeSelection}
            value={"D"}
          />
          <AnswerText
            text={props.question.d}
            showCorrect={showAnswer && props.question.correct_answer === "D"}
            showIncorrect={showAnswer && selectedAnswer !== "D"}
            showCorrectSelected={
              showAnswer &&
              props.question.correct_answer === "D" &&
              selectedAnswer === "D"
            }
            showIncorrectSelected={
              showAnswer &&
              props.question.correct_answer !== "D" &&
              selectedAnswer === "D"
            }
          />
        </div>
        <hr />
      </div>
      <button
        onClick={() => submit()}
        className="bg-blue-500 text-small mt-6 md:text-base block text-white rounded py-2 my-1 px-8 hover:bg-white border-2 text-center border-transparent hover:border-blue-500 hover:text-blue-500 transition-all"
      >
        {showAnswer ? "Weiter" : "Überprüfen"}
      </button>
    </div>
  );
}

type AnswerTextProps = {
  text: string;
  showCorrect: boolean;
  showCorrectSelected: boolean;
  showIncorrect: boolean;
  showIncorrectSelected: boolean;
};

function AnswerText(props: AnswerTextProps) {
  let textStyle;

  if (props.showIncorrect) textStyle = "text-red-500";
  if (props.showCorrect) textStyle = "text-emerald-500";

  return (
    <div>
      <p className={textStyle}>{props.text}</p>
      {props.showCorrectSelected && (
        <p className="text-sm font-bold text-emerald-500">Richtig</p>
      )}
      {props.showIncorrectSelected && (
        <p className="text-sm font-bold text-red-500">Falsch</p>
      )}
    </div>
  );
}

export default QuestionView;
