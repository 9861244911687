import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Question from "../../models/Question";
import TrainingType from "../../models/TrainingType";
import answerService from "../../services/answer.service";
import questionService from "../../services/question.service";
import CategorySelection from "./components/category_selection";
import QuestionView from "./components/question_view";
import { BiChevronLeft } from "react-icons/bi";
import { useNavigate } from "react-router";

function TrainingPage() {
  const [questionSet, setQuestionSet] = useState<Question[]>([]);
  const [currentQuestion, setCurrentQuestion] = useState(-1);
  const navigate = useNavigate();

  function nextQuestion() {
    setCurrentQuestion((prevState) => prevState + 1);
  }

  async function setupTraining(
    pCount: number,
    pType: TrainingType,
    pCategory: string
  ) {
    //Fetch Questions
    let response = await questionService.getQuestion(pCount, pCategory, pType);

    if (!response.data) return toast.error("Ein Fehler ist aufgetreten");

    setQuestionSet(response.data as Question[]);
    nextQuestion();
  }

  function submitQuestion(pSelectedAnswer: string, pCorrect: boolean) {
    answerService
      .postAnswer(questionSet[currentQuestion].id, pCorrect, pSelectedAnswer)
      ?.then(() => nextQuestion());
  }

  let content;

  if (currentQuestion !== -1) {
    content = (
      <QuestionView
        currentQuestionNumber={currentQuestion}
        totalQuestionNumber={questionSet.length}
        question={questionSet[currentQuestion]}
        skip={nextQuestion}
        submitQuestion={submitQuestion}
      />
    );
  } else {
    content = <CategorySelection callback={setupTraining} />;
  }

  return (
    <div>
      <div
        className="flex items-center cursor-pointer"
        onClick={() => navigate("/dashboard")}
      >
        <BiChevronLeft className="mr-2 h-6 w-6" />
        <h1 className="text-3xl">Training</h1>
      </div>
      {content}
    </div>
  );
}

export default TrainingPage;
