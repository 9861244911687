export default function Copyclaim() {
  const currentYear = new Date().getFullYear();
  return (
    <p className="text-slate-400 text-sm my-5 text-center">
      &copy; {currentYear} -{" "}
      <a href="https://tillianbo.com" className="underline">
        Tillian Borchmann
      </a>
    </p>
  );
}
